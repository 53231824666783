import React, {useEffect} from "react";
import store from "../store";
import allActions from "../services/actionsArray";
import shopApi from "../api/shop";
import {MetaWrapper} from "../components/MetaWrapper";
import dynamic from 'next/dynamic'
import {IProps} from "../types/homePageTypes";
import {GetServerSideProps, NextPage} from "next";
import generalAction from "../api/generalAction";
import {getSeparateDomain} from "../services/utils";
import {domainUrl} from "../helper";
import {useRouter} from "next/router";

const HomePageOne = dynamic(() => import("../components/home/HomePageOne"))

const Home: NextPage<IProps> = (props) => {
    const router = useRouter();
    const {
        locale,
        sliderData,
        newProducts,
        featuredProducts,
        metas,
        dbName,
        dispatches,
        currency,
        domain,
        rate,
        getBuilderData,
        homepage_title_text,
        homepage_intro_text,
        hasTracking,
        footerContent,
        isBlogActive
    } = props;
    const {dispatch} = store;
    const firstLoad = true;

    useEffect(() => {
        for (let actionKey in dispatches) {
            // @ts-ignore
            dispatch(allActions[actionKey](dispatches[actionKey]));
        }
    }, [locale, rate]);

    return (
        <MetaWrapper
            dbName={dbName}
            title={!!metas?.meta_title ? metas?.meta_title : homepage_title_text}
            m_title={metas?.meta_title}
            m_desc={metas?.meta_description || dbName}
            m_key={metas?.meta_keywords || dbName}
            m_img={`${domain}/configuration/share_pic/share_pic.webp`}
            m_url={domainUrl(dbName + "/" + locale + router.asPath)}
            locale={locale}
        >
            <HomePageOne
                locale={locale}
                currency={currency}
                headerLayout="default"
                newProducts={newProducts || []}
                featuredProducts={featuredProducts || []}
                metas={metas}
                getBuilderData={getBuilderData}
                firstLoad={firstLoad}
                dbName={dbName}
                rate={rate}
                domain={domain}
                sliderData={sliderData}
                homepage_title_text={homepage_title_text}
                homepage_intro_text={homepage_intro_text}
                hasTracking={hasTracking}
                footerContent={footerContent}
                categories={props.categories}
                isBlogActive={isBlogActive}
                customScriptAnalyze={null}
            />
        </MetaWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async ({locale, req, res}: any) => {
    let selectedCurrency: string = '';
    let selectedRate;
    const dbName = req.headers["x-forwarded-host"];
    const domain = getSeparateDomain(dbName)

    res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59');

    const generalOptions = {
        locale,
        selectedCurrency,
        dbName
    }

    const {
        locale: defaultLocaleSelected,
        rate,
        currency,
        dispatches
    } = await generalAction.action("general", generalOptions, req.m)

    /**** GETTING RATE FOR CURRENCY ****/
    if (Object.keys(req.query).length > 0) {
        selectedRate = rate.currencies_new?.find((item: any) => item.code == selectedCurrency);
    }
    let selectedLocale = locale != "catchAll" ? locale : defaultLocaleSelected;

    /*********** new request async ********/
    const options = {
        locale: selectedLocale,
        limit: 8
    }

    // const promises = [
    //     shopApi.getHomeMetaAsync(domain, selectedLocale, req.m),
    //     shopApi.getHomeProductsAsync(options, domain, req.m),
    //     shopApi.sliderData(domain, selectedLocale, req.m),
    //     shopApi.getBuilderDataAsync(2, domain, selectedLocale, req.m),
    //     shopApi.getSiteTitle(selectedLocale, req.m),
    //     shopApi.getMenus(selectedLocale, req.m),
    //     shopApi.getCategoriesAsync(selectedLocale, domain, req.m),
    //     shopApi.getLocale(selectedLocale, req.m),
    //     shopApi.getCoreConf(selectedLocale, req.m)
    // ];
    //
    // const [
    //     metaTags,
    //     homeFetProd,
    //     sliderData,
    //     getBuilderData,
    //     getSiteTitle,
    //     getMenus,
    //     getCategories,
    //     getLocales,
    //     coreConfigs
    // ] = await Promise.all(promises);



    const metaTags = await shopApi.getHomeMetaAsync(domain, selectedLocale, req.m);
    const homeFetProd = await shopApi.getHomeProductsAsync(options, domain, req.m)
    const sliderData = await shopApi.sliderData(domain, selectedLocale, req.m)
    const getBuilderData = await shopApi.getBuilderDataAsync(2, domain, selectedLocale, req.m)
    const getSiteTitle = await shopApi.getSiteTitle(selectedLocale, req.m)
    const getMenus = await shopApi.getMenus(selectedLocale, req.m)
    const getCategories = await shopApi.getCategoriesAsync(selectedLocale, domain, req.m);
    const getLocales = await shopApi.getLocale(selectedLocale, req.m)
    const coreConfigs = await shopApi.getCoreConf(selectedLocale, req.m);

    const generalDispatches: any = {
        ...dispatches.clientSide,
        ...dispatches.serverSide,
    };

    let arrFooterTrack = [];
    let showSubFooter = "none";
    let isBlogActive = false;

    if (coreConfigs.sales_tracking_tracking_active?.value === "1" || !coreConfigs.hasOwnProperty("sales_tracking_tracking_active")) {
        arrFooterTrack.push({href: "/tracking", id: "tracking", defaultMessage: "Tracking Number"})
    }
    if (coreConfigs.theme_contact_us_active?.value === "1" || !coreConfigs.hasOwnProperty("theme_contact_us_active")) {
        arrFooterTrack.push({href: "/contact", id: "contact_with_us", defaultMessage: "Contact With Us"})
    }
    if (coreConfigs.theme_subscription_active?.value === "1" || !coreConfigs.hasOwnProperty("theme_subscription_active")) {
        showSubFooter = "block";
    }
    if (coreConfigs.theme_blog_active?.value === "1" || !coreConfigs.hasOwnProperty("theme_blog_active")) {
        isBlogActive = true;
    }

    const footerContent = coreConfigs.general_content_footer_footer_content?.find((item: any) => item && item?.locale_code === selectedLocale);
    const footerToggle = coreConfigs.general_content_footer_footer_toggle?.find((item: any) => item && item?.locale_code === selectedLocale);

    const { general_content_custom_scripts_custom_javascript, general_content_custom_scripts_custom_css } = coreConfigs || {};
    const { value: customScriptAnalyze } = general_content_custom_scripts_custom_javascript || {};
    const { value: customCssAnalyze } = general_content_custom_scripts_custom_css || {};

    return {
        props: {
            locale: defaultLocaleSelected,
            currency,
            rate: selectedRate?.exchange_rate?.rate || 1,
            dbName,
            sliderData: sliderData,
            metas: metaTags,
            menus: getMenus,
            featuredProducts: JSON.parse(JSON.stringify(homeFetProd.featuredProducts)),
            newProducts: JSON.parse(JSON.stringify(homeFetProd.newProduct)),
            dispatches: generalDispatches,
            getBuilderData: getBuilderData[0] || {},
            domain,
            homepage_title_text: getSiteTitle.translations.homepage_title_text,
            homepage_intro_text: getSiteTitle.translations.homepage_intro_text,
            categories: getCategories ?? null,
            topBarLocales: getLocales[0].locales,
            hasTracking: arrFooterTrack,
            footerContent: footerContent ?? null,
            footerToggle: footerToggle ?? null,
            showSubscription: showSubFooter ?? null,
            isBlogActive: isBlogActive,
            customScriptAnalyze: customScriptAnalyze ?? null,
            customCssAnalyze: customCssAnalyze ?? null
        },
    };

}

export default Home;
