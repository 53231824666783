import {
    setDomain,
    setSocial,
    setFbPixel,
    setPopup,
    setMenuList,
    setCategories,
    setCustomJs,
    setBackorders,
    setCacheVersion,
    setTranslations,
    setCoreConfigs,
    setInitialMinPrice,
    setInitialMaxPrice,
} from "../store/general";

import {AddCartToken} from "../store/token";
import {changeCurrency, setCurrencies} from "../store/currency";
import {setLocaleList, changeLocale} from "../store/locale";
import {setRate} from "../store/rate";


export default {
    setDomain,
    setSocial,
    setFbPixel,
    setPopup,
    setRate,
    setCustomJs,
    setBackorders,
    setCacheVersion,
    setCoreConfigs,
    setTranslations,
    AddCartToken,
    changeCurrency,
    setCurrencies,
    setLocaleList,
    changeLocale,
    setMenuList,
    setCategories,
    setInitialMinPrice,
    setInitialMaxPrice,
};